<template>
    <div>
        <el-form :inline="true" :model="activeForm" class="demo-form-inline select-wrap" >
            <el-form-item label="请选择活动">
                <el-select v-model="activeForm.activeNo"
                           filterable
                           clearable
                           remote
                           :remote-method="initActiveList"
                           @change="activeChange"
                           placeholder="输入活动名称查询">
                    <el-option v-for="item in activeList"
                               :key="item.activeNo"
                               :label="item.activeName"
                               :value="item.activeNo">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="mini" @click="initList">查询</el-button>
            </el-form-item>
        </el-form>
        <tablePagination :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
            <el-table :data="joinList" stripe border center v-loading="loading">
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <!--<el-table-column prop="inviteId" label="邀请标识" width="150"></el-table-column>-->
                <el-table-column prop="activeName" label="活动名称" width="170" sortable></el-table-column>
                <el-table-column label="头像">
                    <template slot-scope="scope">
                        <el-avatar size="small" :src="scope.row.headImageUrl"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column prop="nickName" label="昵称" width="170">
                </el-table-column>
                <el-table-column label="邀请">
                    <template slot-scope="scope">
                        <span>{{scope.row.inviteCount}}人</span>
                    </template>
                </el-table-column>
                <el-table-column label="已兑换">
                    <template slot-scope="scope">
                        <span>{{scope.row.usedCount}}人</span>
                    </template>
                </el-table-column>
                <el-table-column label="剩余">
                    <template slot-scope="scope">
                        <span>{{(scope.row.status==2&&(scope.row.activeType==22||scope.row.activeType==19))?scope.row.balanceCount:'-'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="参与时间"></el-table-column>
                <el-table-column prop="orderMainNo" label="订单号">
                    <template slot-scope="scope">
                        <a :href="getOrderLink(scope.row.orderMainNo)" alt="点击查看订单详情"
                           target="_blank">{{scope.row.orderMainNo==''?'无':scope.row.orderMainNo}}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="statusName" label="状态" width="120" sortable>
                </el-table-column>
                <el-table-column label="操作" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" v-show="scope.row.isPrize>=1&&scope.row.inviteCount>0"
                                   @click="getInviteDetail(scope.row)">邀请明细
                        </el-button>
                        <!--<el-button type="success" v-show="scope.row.isPrize==1&&scope.row.inviteCount>=1" size="mini" @click="prize(scope.row)">协助兑奖</el-button>-->
                    </template>
                </el-table-column>

            </el-table>
        </tablePagination>
        <el-dialog title="邀请明细" :visible.sync="showDialog">
            <el-table :data="inviteList" border size="mini">
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <el-table-column prop="activeName" label="活动名称" width="170"></el-table-column>
                <el-table-column prop="headImageUrl" label="头像">
                    <template slot-scope="scope">
                        <el-avatar size="small" :src="scope.row.headImageUrl"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column prop="nickName" label="昵称" width="170">
                    <template slot-scope="scope">
                        <a :href="getLink(scope.row.customerId)" alt="点击查看邀请用户"
                           target="_blank">{{scope.row.nickName == null ? '未关注用户' : scope.row.nickName}}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="orderMainNo" label="订单号" width="170">
                    <template slot-scope="scope">
                        <a :href="getOrderLink(scope.row.orderMainNo)" alt="点击查看用户订单"
                           target="_blank">{{scope.row.orderMainNo}}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="活动参与时间"></el-table-column>
                <el-table-column prop="statusName" label="状态" fixed="right" width="80" sortable>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
    import tablePagination from '@c/tablePagination'
    export default {
        data() {
            return {
                formParam: {
                    openId: this.$route.params.openId,
                    customerId:this.$route.params.id,
                    activeNo:'',
                    currentPage: 1,
                    pageSize: 10
                },
                activeForm:{
                    activeNo: "",
                    activeName: "",
                    activeType:'',
                    currentPage: 1,
                    pageSize: 20,
                    status:''
                },
                total: 0,
                loading: true,
                joinList: [],
                activeList:[],
                showDialog: false,
                inviteList: []
            }
        },
        components: {
            tablePagination
        },
        mounted(){
            console.log(this.$route.params.id);
            this.initList();
        },
        methods: {
            initList() {
                this.loading = true;
                this.$apiGet('lechun-market/invite/getJoinRcordList', this.formParam).then(res => {
                    console.log(res)
                    this.loading = false;
                    this.total = res.total;
                    this.joinList = res.list
                })
            },
            initActiveList(activeName){
                this.activeForm.activeName=activeName;
                this.$apiGet('lechun-cms/activeManage/getActiveList', this.activeForm).then(res => {
                    console.log(res);
                    //this.formInline.recordsTotal = res.data.value.total;
                    this.activeList = res.list;
                })
            },
            activeChange(item){
                this.formParam.activeNo=item;
            },
            handlePage(page){
                this.formParam.currentPage = page;
                this.initList()
            },
            toDetail(id){
                this.$router.push({path: '/userDetail/:id', name: 'userDetail', params: {id: id}})
            },
            getInviteDetail(row){
                this.inviteList = row.inviteList;
                this.showDialog = true;
            },
            getOrderLink(orderMainNo){
                return `${window.location.origin}/orderList?externalMainOrderNo=${orderMainNo}`
            },
            getLink(id){
                let returnUrl = `${window.location.origin}/userDetail/${id}`
                return returnUrl;
            },
            convertStatus(row, column, cellValue) {
                if (row.status == 1) {
                    return "启用";
                } else {
                    return "禁用";
                }
            }
        },

    }
</script>


<style scoped>

</style>